<template>
  <div>
    <v-dialog
      v-model="isOpenDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      :transition="
        $vuetify.breakpoint.xs
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      "
      :max-width="width"
      persistent
    >
      <v-card>
        <div class="primary white--text">
          <v-card-title>
            รายละเอียดแผนซ่อมบำรุง
            <v-btn icon class="ml-auto white--text" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </div>
        <v-card-text class="ma-0 pa-0">
          <v-form
            ref="form"
            lazy-validation
            class="pt-0"
            enctype="multipart/form-data"
          >
            <div class="pa-4">
              <v-row>
                <v-col cols="12">
                  <v-row align="center">
                    <v-col class="" cols="4" sm="3">
                      ปีงบประมาณ
                      <span class="red--text pl-1">*</span>
                    </v-col>
                    <v-col cols="8" sm="4">
                      <v-text-field
                        v-model="editForm.budget_year"
                        class="right-input"
                        type="number"
                        dense
                        outlined
                        hide-details="auto"
                        :disabled="isLoading"
                        readonly
                        background-color="#e1e1e1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row align="center">
                    <v-col cols="4" sm="3">
                      ชื่อแผนซ่อมบำรุง
                      <span class="red--text pl-1">*</span>
                    </v-col>
                    <v-col cols="8" sm="9">
                      <v-text-field
                        v-model="editForm.name"
                        dense
                        outlined
                        hide-details="auto"
                        :disabled="isLoading"
                        :readonly="$store.getters.edit_repair ? readonly : true"
                        :rules="!readonly ? [rules.required] : []"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row align="center">
                    <v-col cols="4" sm="3">
                      สะพาน
                      <span class="red--text pl-1">*</span>
                    </v-col>
                    <v-col cols="8" sm="9">
                      <v-text-field
                        v-model="editForm.bridge_name"
                        dense
                        outlined
                        hide-details
                        :disabled="isLoading"
                        readonly
                        background-color="#e1e1e1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div class="mt-4 d-flex justify-space-between">
                <h3 class="primary--text">รายละเอียด</h3>
                <!-- <div class="font-weight-bold primary--text">รายละเอียด</div> -->
                <div class="d-flex">
                  <div><v-badge color="green" inline left dot></v-badge>ดี</div>
                  <div>
                    <v-badge color="yellow" inline left dot></v-badge>ปานกลาง
                  </div>
                  <div>
                    <v-badge color="orange" inline left dot></v-badge>บกพร่อง
                  </div>
                  <div>
                    <v-badge color="red" inline left dot></v-badge>วิกฤต
                  </div>
                </div>
              </div>

              <v-card
                class="pa-2"
                style="border: 1px solid #cbcbcb; width: 100%"
                elevation="0"
                outlined
              >
                <DetailTable
                  v-if="value"
                  v-model="value"
                  ref="maintenanceTable"
                  :repairId="repairId"
                  :items="editForm.items"
                  :readonly="$store.getters.edit_repair ? readonly : true"
                  :customPrices="customPrices"
                  :isLoading="isLoading"
                  @updateOption="updateOption"
                  @updateCustom="updateCustom"
                />
              </v-card>
              <v-row
                v-if="!readonly && $store.getters.edit_repair"
                class="ma-0 mt-4"
                align="center"
                justify="end"
              >
                <v-checkbox
                  v-model="isDone"
                  class="ma-0 pa-0"
                  hide-details
                  label="ดำเนินการเสร็จสิ้น รอจัดสรรงบประมาณ"
                ></v-checkbox>
              </v-row>
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="!readonly && $store.getters.edit_repair">
          <v-btn
            v-if="!isAdd"
            color="primary"
            outlined
            elevation="0"
            style="width: 140px"
            :disabled="isDisabledSubmit || isUpdateLoading"
            @click="isConfirmDeleteDialog = true"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
            ลบข้อมูล
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            v-if="$store.getters.edit_repair"
            class="green white--text"
            elevation="0"
            style="width: 140px"
            :disabled="isDisabledSubmit || isUpdateLoading"
            @click="openConfirmDialog"
          >
            บันทึก
            <v-progress-circular
              v-if="isUpdateLoading"
              color="white"
              :size="20"
              indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="isConfirmDeleteDialog"
      :dialog-title="'ยืนยันการลบข้อมูล ?'"
      :dialog-icon="'mdi-close-circle-outline'"
      :dialog-icon-color="'primary'"
      @confirm="confirmDelete()"
      @cancel="closeConfirmDialog()"
    />
    <ConfirmDialog
      v-model="isConfirmDialog"
      :dialog-title="dialogTitle"
      :isLoading="isLoading"
      @confirm="confirm()"
      @cancel="closeConfirmDialog()"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
import DetailTable from '../DetailTable.vue'
export default {
  components: {
    ConfirmDialog,
    DetailTable
  },
  props: {
    value: Boolean,
    title: String,
    width: {
      type: Number,
      default: () => {
        return 950
      }
    },
    isDisabledSubmit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isUpdateLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isAdd: Boolean,
    readonly: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    repairId: Number
  },
  data: () => ({
    isOpenDialog: false,
    isConfirmDeleteDialog: false,
    isConfirmDialog: false,
    optionMaintenance: null,
    maintenanceHeaders: [
      {
        text: 'รหัส',
        value: 'score.ref_damage_indepth_group_id',
        sortable: false
      },
      {
        text: 'ความเสียหาย',
        value: 'score.damage_indepth_group_name',
        sortable: false
      },
      { text: 'ปริมาณ', value: 'amount', sortable: false },
      {
        text: 'วิธีซ่อมบำรุง (แนะนำ)',
        value: 'repair_recommended.name',
        sortable: false
      },
      {
        text: 'วิธีซ่อมบำรุง (สอดคล้องงบประมาณ)',
        value: 'repair_custom',
        align: 'center',
        sortable: false
      },
      { text: 'ราคา (บาท)', value: 'price', sortable: false, align: 'right' }
    ],
    maintenanceItems: [],
    maintenanceMethodOption: [],
    optionAddon: null,
    editForm: {},
    isLoading: false,
    maintenanceOption: [],
    refRepair: [],
    expanded: [],
    isDone: false,
    dialogTitle: 'ยืนยันการบันทึกข้อมูล ?',

    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล'
    },
    customPrices: []
  }),
  watch: {
    value (newVal, oldVal) {
      this.isOpenDialog = newVal
      if (newVal) {
        this.getMethodBridgeRepair()
      } else {
        if (this.$refs.form) this.$refs.form.reset()
      }
    }
  },
  async mounted () {
    await this.getRefRepair()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    clearForm () {
      this.$emit('clear')
    },
    async confirm () {
      this.isLoading = true
      this.dialogTitle = 'กำลังบันทึกข้อมูล'
      const result = await this.setMethodBridgeRepair(this.editForm.id, {
        name: this.editForm.name
      })
      if (result) {
        for await (const item of this.editForm.items) {
          const repairDetail = item.repair_custom
          if (repairDetail) {
            await this.setMethodBridgeRepairDetail(
              item.id,
              repairDetail.id,
              repairDetail.selected_option_codes
            )
          }
        }
        await this.$store.dispatch('repair/setMethodRepairExtraOption', {
          bridgeRepairId: this.repairId,
          values: this.customPrices
        })
        if (this.isDone) {
          await this.setMethodBridgeRepairDone()
        }
      }
      this.isConfirmDialog = false
      this.isLoading = false
      this.dialogTitle = 'ยืนยันการบันทึกข้อมูล ?'
      this.$emit('confirm')
    },
    openConfirmDialog () {
      if (this.$refs.form.validate()) {
        this.isConfirmDialog = true
      } else {
        const invalidField = this.$refs.form.inputs.find((e) => !e.valid)
        if (invalidField) {
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      }
    },
    closeConfirmDialog () {
      this.isConfirmDialog = false
      this.isConfirmDeleteDialog = false
    },
    confirmDelete () {
      this.$emit('delete')
      this.isConfirmDeleteDialog = false
    },
    async getRefRepair () {
      this.isLoading = true
      const items = await this.$store.dispatch('ref/getRef', {
        method: 'repair'
      })
      this.refRepair = items
      this.isLoading = false
    },
    async getMethodBridgeRepair () {
      this.isLoading = true
      this.editForm = {}
      this.customPrices = []
      if (this.$refs.form) this.$refs.form.reset()
      this.editForm =
        (await this.$store.dispatch('repair/getMethodBridgeRepair', {
          id: this.repairId
        })) || {}
      this.customPrices = await this.$store.dispatch(
        'repair/getMethodRepairExtraOption',
        { bridgeRepairId: this.repairId }
      )
      this.isDone = this.editForm.ref_bridge_repair_status_id === '2'
      this.isLoading = false
    },

    updateOption (param) {
      const newitem = this.editForm.items.find(
        (option) => option.id === param.id
      )
      if (newitem.repair_custom) {
        newitem.repair_custom.selected_option_codes = param.selectedOptionCodes
        newitem.repair_custom.id = param.refRepair.id
        newitem.repair_custom.name = param.refRepair.name
      } else {
        console.log(param)
        newitem.repair_custom = {
          selected_option_codes: param.selectedOptionCodes,
          id: param.refRepair.id,
          name: param.refRepair.name
        }
      }
      newitem.sum_price = param.price
    },
    async setMethodBridgeRepair (bridgeRepairId, editForm) {
      const isEdit = await this.$store.dispatch(
        'repair/setMethodBridgeRepair',
        {
          bridgeRepairId,
          payload: editForm
        }
      )
      return isEdit
    },
    async setMethodBridgeRepairDetail (
      bridgeRepairDetailId,
      refRepairId,
      selectedOptionCodes
    ) {
      await this.$store.dispatch('repair/setMethodBridgeRepairDetail', {
        bridgeRepairDetailId,
        refRepairId,
        optionCodes: selectedOptionCodes
      })
    },
    async setMethodBridgeRepairDone () {
      await this.$store.dispatch('repair/setMethodBridgeRepairDone', {
        bridgeRepairId: this.editForm.id
      })
    },
    updateCustom (customPrices) {
      this.customPrices = customPrices
    }
  }
}
</script>

<style scoped>
.price--text {
  color: #036397;
}

.table-mobile {
  width: 50%;
}

@media screen and (max-width: 600px) {
  th,
  td {
    padding: 6px;
    text-align: left;
  }
}

tr:hover.option-card {
  background-color: transparent !important;
}
tr.option-card td {
  border: none !important;
  border-bottom: none;
}
tbody:hover.option-card tr {
  /* background: transparent !important; */
  background-color: #f0f1f4 !important;
}
.right-input >>> input {
  text-align: right;
}
tr.noBorder td {
  border-bottom: none !important;
}
</style>
