<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span class="price--text" v-bind="attrs" v-on="on">
        {{ getPrice(parseFloat(optionlist.amount)) || 0 }}
        {{ optionlist.unit.name }}
        {{ getPrice(optionlist.sum_price) }}
        บาท
      </span>
    </template>
    <div v-if="optionlist.price_lumpsum !== null">
      <ul>
        <li>
          Lump sum {{ getPrice(optionlist.sum_price) }} บาท ({{
            getPrice(optionlist.price_lumpsum / optionlist.amount || 0)
          }}
          บาท/หน่วย)
        </li>
      </ul>
    </div>
    <div v-else>
      <ul>
        <li>
          ค่าวัสดุ
          {{ getPrice(optionlist.price_material) }}
          บาท ({{
            getPrice(optionlist.price_material / optionlist.amount || 0)
          }}
          บาท/หน่วย)
        </li>
        <li>
          ค่าแรง {{ getPrice(optionlist.price_wage) }} บาท ({{
            getPrice(optionlist.price_wage / optionlist.amount || 0)
          }}
          บาท/หน่วย)
        </li>
      </ul>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    optionlist: Object
  },
  methods: {
    getPrice (price) {
      const price2digit = (price || 0).toFixed(2)
      return parseFloat(price2digit).toLocaleString()
    }
  }
}
</script>

<style>
.price--text {
  color: #036397;
}
</style>
