<template>
  <div>
    <v-data-table
      ref="maintenanceTable"
      :headers="maintenanceHeaders"
      :items="items"
      :loading="isLoading"
      group-by="ref_bridge_component_id"
      :footer-props="{
        'items-per-page-text': 'จำนวนแถว'
      }"
      no-data-text="ไม่มีข้อมูล"
    >
      <template v-slot:group.header="{ group }">
        <td v-if="$vuetify.breakpoint.smAndUp" colspan="6" class="white">
          <b class="primary--text">
            {{ group }}
            <span v-if="!isLoadingComponent">
              {{ getComponentName(group) }}
            </span>
          </b>
        </td>
        <v-row v-else class="ma-0 white">
          <v-col cols="auto">
            <b class="primary--text">
              {{ group }} ({{
                (
                  (itemLists.bridge_component || []).find(
                    (component) => component.id === group
                  ) || {}
                ).name
              }})
            </b>
          </v-col>
        </v-row>
      </template>
      <template
        v-if="$vuetify.breakpoint.smAndUp"
        v-slot:item="{ item, expand, isExpanded }"
      >
        <tr class="primary-light">
          <th :colspan="maintenanceHeaders.length">
            {{
              `${item.bridge_part_code}-${item.ref_bridge_sector_id}${item.bridge_sector_no}-${item.ref_bridge_component_id}${item.bridge_component_no}`
            }}
          </th>
        </tr>
        <tr>
          <td>
            {{ item.score.ref_damage_indepth_group_id }}
          </td>
          <td>{{ item.score.damage_indepth_group_name }}</td>
          <td class="text-center">
            <v-chip
              class="white--text"
              :color="
                item.score.score_indepth_name === 'ดี'
                  ? 'green'
                  : item.score.score_indepth_name === 'ปานกลาง'
                  ? 'yellow'
                  : item.score.score_indepth_name === 'บกพร่อง'
                  ? 'orange'
                  : 'red'
              "
              small
              label
            >
              {{ item.amount.toLocaleString() }}
            </v-chip>
          </td>
          <td>{{ item.repair_recommended.name }}</td>
          <td class="text-center">
            <a
              class="text-decoration-underline"
              @click="setExpandedAll(expand, isExpanded)"
            >
              <span v-if="item.repair_custom">
                {{ item.repair_custom.name }} <br />
                <span v-if="item.repair_custom.selected_option_codes.length">
                  ({{
                    item.repair_custom.selected_option_codes
                      .map((option) => `Option ${option}`)
                      .join(', ')
                  }})
                </span>
              </span>
              <span v-else>-เลือกวิธีซ่อม-</span>
            </a>
          </td>
          <td class="text-right">
            {{ parseFloat(item.sum_price || 0).toLocaleString() }}
          </td>
          <td width="0px"></td>
        </tr>
      </template>
      <template
        v-else
        v-slot:item="{ headers, item, expand, isExpanded, index }"
      >
        <table>
          <tr class="primary-light v-row-group__header">
            <td colspan="2" class="">
              <b>
                {{
                  `${item.bridge_part_code}-${item.ref_bridge_sector_id}${item.bridge_sector_no}-${item.ref_bridge_component_id}${item.bridge_component_no}`
                }}
              </b>
            </td>
            <td colspan="4"></td>
          </tr>
          <tr>
            <th class="text-left table-mobile">
              {{ headers[0].text }}
            </th>
            <td class="v-data-table__mobile-row">
              {{ item.score.ref_damage_indepth_group_id }}
            </td>
          </tr>
          <tr class="mt-4">
            <th class="text-left">{{ headers[1].text }}</th>
            <td class="v-data-table__mobile-row">
              {{ item.score.damage_indepth_group_name }}
            </td>
          </tr>
          <tr>
            <th class="text-left">{{ headers[2].text }}</th>
            <td class="v-data-table__mobile-row">
              <v-chip
                class="white--text"
                :color="
                  item.score.score_indepth_name === 'ดี'
                    ? 'green'
                    : item.score.score_indepth_name === 'ปานกลาง'
                    ? 'yellow'
                    : item.score.score_indepth_name === 'บกพร่อง'
                    ? 'orange'
                    : 'red'
                "
                small
                label
              >
                {{ item.amount.toLocaleString() }}
              </v-chip>
            </td>
          </tr>
          <tr>
            <th class="text-left">{{ headers[3].text }}</th>
            <td class="v-data-table__mobile-row">
              {{ item.repair_recommended.name }}
            </td>
          </tr>
          <tr>
            <th class="text-left">{{ headers[4].text }}</th>
            <td class="v-data-table__mobile-row">
              <a class="text-decoration-underline" @click="expand(!isExpanded)">
                <span v-if="item.repair_custom">
                  {{ item.repair_custom.name }} <br />
                  <span v-if="item.repair_custom.selected_option_codes.length">
                    ({{
                      item.repair_custom.selected_option_codes
                        .map((option) => `Option ${option}`)
                        .join(', ')
                    }})
                  </span>
                </span>
                <span v-else>-เลือกวิธีซ่อม-</span>
              </a>
            </td>
          </tr>
          <tr>
            <th class="text-left">{{ headers[5].text }}</th>
            <td class="v-data-table__mobile-row">
              {{ parseFloat(item.sum_price || 0).toLocaleString() }}
            </td>
          </tr>
        </table>
        <v-divider v-if="index < items.length - 1 && !isExpanded"></v-divider>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <v-slide-y-transition hide-on-leave>
          <td :colspan="headers.length">
            <RepairOptionCard
              :repairId="repairId"
              :item="item"
              :readonly="readonly"
              @updateOption="updateOption"
            />
          </td>
        </v-slide-y-transition>
        <v-divider></v-divider>
      </template>
      <template v-slot:body.append="{ headers, isMobile }">
        <tr class="primary-light">
          <th :colspan="maintenanceHeaders.length">
            อื่นๆ
            <v-btn
              v-if="!readonly"
              icon
              :disabled="isLoading"
              @click="customPricesForm.push({})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </th>
        </tr>
        <tr v-for="(price, indexprice) in customPricesForm" :key="indexprice">
          <td colspan="4">
            <v-text-field
              v-model="customPricesForm[indexprice].name"
              outlined
              hide-details="auto"
              placeholder="วิธีการซ่อม"
              :rules="[rules.required]"
              dense
            ></v-text-field>
          </td>
          <td colspan="2">
            <v-text-field
              v-model="customPricesForm[indexprice].price_per_unit"
              class="right-input"
              type="number"
              outlined
              hide-details="auto"
              placeholder="0"
              dense
              :rules="[rules.required]"
            ></v-text-field>
          </td>
          <td align="end">
            <v-btn icon @click="delcustomPricesForm(indexprice)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr v-if="!isMobile" class="primary white--text">
          <th class="text-right" :colspan="!isMobile ? headers.length - 1 : 0">
            รวม
          </th>
          <th class="text-right">
            {{ sumPrice }}
          </th>
        </tr>
        <div v-else>
          <v-row class="ma-0 primary white--text">
            <v-col class="pr-0"><b>รวม (บาท)</b></v-col>
            <v-col class="pl-0">
              <b>
                {{ sumPrice }}
              </b>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
    <ConfirmDialog
      v-model="isConfirmDialog"
      :dialog-title="'ยืนยันการลบข้อมูล'"
      :dialog-icon="'mdi-close-circle-outline'"
      :dialog-icon-color="'primary'"
      @confirm="confirmDel()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import RepairOptionCard from './RepairOptionCard.vue'
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
export default {
  props: {
    value: Boolean,
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    repairId: Number,
    readonly: Boolean,
    isLoading: Boolean,
    customPrices: Array
  },
  components: {
    RepairOptionCard,
    ConfirmDialog
  },
  watch: {
    value (newVal, oldVal) {
      if (!newVal) this.setExpanded()
    },
    customPrices (newVal, oldVal) {
      this.customPricesForm = newVal
    },
    customPricesForm (newVal, oldVal) {
      this.$emit('updateCustom', newVal)
    }
  },
  computed: {
    sumPrice () {
      return (
        this.items.reduce((a, b) => a + (parseFloat(b.sum_price) || 0), 0) +
        this.customPricesForm.reduce(
          (a, b) => a + (parseFloat(b.price_per_unit) || 0),
          0
        )
      ).toLocaleString()
    }
  },
  data: () => ({
    maintenanceHeaders: [
      {
        text: 'รหัส',
        value: 'score.ref_damage_indepth_group_id',
        sortable: false
      },
      {
        text: 'ความเสียหาย',
        value: 'score.damage_indepth_group_name',
        sortable: false
      },
      { text: 'ปริมาณ', align: 'center', value: 'amount', sortable: false },
      {
        text: 'วิธีซ่อมบำรุง (แนะนำ)',
        value: 'repair_recommended.name',
        sortable: false
      },
      {
        text: 'วิธีซ่อมบำรุง (สอดคล้องงบประมาณ)',
        value: 'repair_custom',
        align: 'center',
        sortable: false
      },
      { text: 'ราคา (บาท)', value: 'price', sortable: false, align: 'right' },
      {
        sortable: false,
        align: 'right',
        width: '10px'
      }
    ],
    itemLists: {},
    isLoadingComponent: false,
    isConfirmDialog: false,
    indexDel: null,

    customPricesForm: [],
    rules: {
      required: (value) => !!value || 'กรุณากรอกข้อมูล'
    }
  }),
  async beforeMount () {
    await this.getRef('bridge_component')
  },
  methods: {
    updateOption (option) {
      this.$emit('updateOption', option)
    },
    async setExpanded () {
      for (let i = 0; i < this.items.length; i += 1) {
        const item = this.items[i]
        this.$set(this.$refs.maintenanceTable.expanded, item.id, false)
      }
    },
    async setExpandedAll (expand, isExpanded) {
      await this.setExpanded()
      expand(!isExpanded)
    },
    async getRef (method) {
      this.isLoadingComponent = true
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
      this.isLoadingComponent = false
    },
    getComponentName (componentId) {
      const item = (this.itemLists.bridge_component || []).find(
        (component) => component.id === componentId
      )
      return (item || {}).name ? `(${(item || {}).name})` : ''
    },
    delcustomPricesForm (index) {
      console.log(index)
      this.indexDel = index
      this.isConfirmDialog = true
    },
    confirmDel () {
      this.customPricesForm.splice(this.indexDel, 1)
      this.isConfirmDialog = false
    },
    closeConfirm () {
      this.isConfirmDialog = false
    }
  }
}
</script>

<style scoped>
.table-mobile {
  width: 50%;
}
tr.v-row-group__header {
  background: white;
  background-color: white;
}
.right-input >>> input {
  text-align: right;
}
</style>
