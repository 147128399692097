<template>
  <v-card
    class="ma-2"
    elevation="0"
    color="#FFFAEA"
    style="border: 2px solid #ffd653"
    transition="scale-transition"
    origin="center center"
  >
    <div class="pa-4">
      <div>
        <v-row align="center" justify="center">
          <v-col cols="auto">
            วิธีการซ่อมบำรุงสอดคล้องงบประมาณ
            <span class="red--text pl-1">*</span>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="refRepair"
              :items="refRepairList"
              background-color="white"
              outlined
              dense
              hide-details
              item-text="name"
              return-object
              :readonly="readonly"
              :disabled="readonly"
              placeholder="เลือก"
              no-data-text="ไม่มีวิธีซ่อมบำรุงให้เลือก"
              @change="getCalculateMethodRepair(refRepair.id)"
            >
              <template v-slot:selection="{ item }">
                <span
                  style="
                    display: -webkit-box;
                    max-width: 100%;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  "
                  >{{ item.name }}</span
                >
              </template>
            </v-select>
          </v-col>
        </v-row>
      </div>
      <v-slide-y-transition hide-on-leave>
        <v-radio-group
          v-model="optionCodeRadio"
          hide-details
          :readonly="readonly"
          :disabled="readonly"
          v-if="!isLoading"
        >
          <v-row
            v-for="option in optionList"
            :key="option.option_code"
            class="ma-0"
            align="center"
          >
            <v-radio
              v-if="!option.is_addon"
              color="primary"
              :value="option.option_code"
            >
              <template v-slot:label>
                <b :class="{ 'black--text': !readonly }">
                  Option {{ option.option_code }}
                  <span :class="{ 'price--text': !readonly }">
                    ({{ getPrice(option.sumPrice) }} บาท)
                  </span>
                </b>
              </template>
            </v-radio>
            <v-checkbox
              v-else
              v-model="optionCodeCheckbox"
              class="ma-0 pa-0"
              :value="option.option_code"
              :readonly="readonly"
              :disabled="readonly"
              hide-details
            >
              <template v-slot:label>
                <b :class="{ 'black--text': !readonly }">
                  Option {{ getPrice(option.option_code) }}
                  <span :class="{ 'price--text': !readonly }">
                    ({{ getPrice(option.sumPrice) }} บาท)
                  </span>
                </b>
              </template>
            </v-checkbox>
            <v-col cols="12" class="mt-0 pt-0">
              <ul class="text-wrap">
                <li
                  v-for="(optionlist, indexOptionlist) in option.items"
                  :key="`optionlist-${indexOptionlist}`"
                  class="text-wrap"
                >
                  <span class="text-wrap">{{ optionlist.name }}</span>
                  <span
                    v-if="optionlist.is_finding_price"
                    class="primary--text"
                  >
                    **{{ optionlist.note }}
                  </span>

                  <PriceOptionTooltip
                    v-if="'amount' in optionlist"
                    :optionlist="optionlist"
                  />
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-radio-group>

        <v-row v-else align="center" class="ma-0" justify="center">
          <v-progress-circular
            color="grey lighten-2"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-slide-y-transition>
    </div>
  </v-card>
</template>

<script>
import PriceOptionTooltip from './PriceOptionTooltip.vue'
export default {
  props: {
    item: Object,
    repairId: Number,
    readonly: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  components: {
    PriceOptionTooltip
  },
  data: () => ({
    refRepairList: [],
    isLoading: false,
    refRepair: null,
    optionList: [],
    optionCode: [],
    optionCodeRadio: null,
    optionCodeCheckbox: []
    // item: {}
  }),
  watch: {
    refRepair (newVal, oldVal) {
      if (this.item.repair_custom) {
        if (newVal.id !== this.item.repair_custom.id) {
          this.optionCodeRadio = null
          this.optionCodeCheckbox = []
        }
      }
      if (newVal) this.returnValue()
    },
    optionCodeRadio (newVal, oldVal) {
      if (newVal) this.returnValue()
    },
    optionCodeCheckbox (newVal, oldVal) {
      this.returnValue()
    }
  },
  async mounted () {
    await this.getRefRepair()
    const refRepair = this.item.repair_custom
      ? this.item.repair_custom.id
      : this.item.repair_recommended.id
    if (refRepair) await this.getCalculateMethodRepair(refRepair)

    this.refRepair = this.refRepairList.find(
      (repair) => repair.id === refRepair
    )
    // await this.getCalculateMethodRepair(refRepair)
    // set selected option to method repair
    if (this.item.repair_custom) {
      const selectedOptionCodes = this.item.repair_custom.selected_option_codes
      for (const option of this.optionList) {
        if (selectedOptionCodes.includes(option.option_code)) {
          if (!option.is_addon) {
            this.optionCodeRadio = option.option_code
          } else {
            this.optionCodeCheckbox.push(option.option_code)
          }
        }
      }
    }
  },
  methods: {
    getPrice (price) {
      const price2digit = parseFloat(price).toFixed(2)
      return parseFloat(price2digit).toLocaleString()
    },

    async getRefRepair () {
      this.isLoading = true
      const items = await this.$store.dispatch('ref/getRef', {
        method: 'repair-mapping',
        filter: {
          component_id: this.item.ref_bridge_component_id,
          material_id: this.item.material_id
        }
      })
      this.refRepairList = items
      this.isLoading = false
    },
    async getCalculateMethodRepair (item) {
      this.isLoading = true
      this.optionList = await this.$store.dispatch(
        'repair/getCalculateMethodRepair',
        {
          bridgeRepairDetailId: this.item.id,
          refRepairId: item,
          dateCalculated: item.date_calculated
        }
      )
      for (const item of this.optionList) {
        item.sumPrice = item.items.reduce((a, b) => a + (b.sum_price || 0), 0)
      }
      // if (!this.readonly) {
      //   const optionCodeRadioList = this.optionList.filter(
      //     (method) => !method.is_addon
      //   )
      //   console.log(optionCodeRadioList)
      //   if (optionCodeRadioList.length) {
      //     this.optionCodeRadio = optionCodeRadioList.reduce((prev, current) =>
      //       prev.sumPrice > current.sumPrice ? prev : current
      //     ).option_code
      //   }
      // }
      this.isLoading = false
    },
    returnValue () {
      const selectedOptionCodes = [
        ...[this.optionCodeRadio, ...this.optionCodeCheckbox].filter(
          (option) => option
        )
      ]
      const price = selectedOptionCodes.map((option) => {
        return this.optionList.find(
          (optioncode) => optioncode.option_code === option
        ).sumPrice
      })
      const param = {
        id: this.item.id,
        bridgeRepairDetailId: this.repairId,
        refRepair: this.refRepair,
        selectedOptionCodes,
        price: price.reduce((a, b) => a + b, 0)
      }
      this.$emit('updateOption', param)
    }
  }
}
</script>

<style scoped>
.price--text {
  color: #036397;
}
</style>
